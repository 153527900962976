@use "../variables";
@use '../mixins';

.EventsWidget {

  @include mixins.md-up {
    flex-direction: row;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 7rem;
    border-bottom: 1px solid variables.$gray;
    @include mixins.md-up {
      flex: 1;
      padding: 0 1rem 0 0;
      border-bottom: none;
      max-width: calc(100% - 175px);
    }
    .number {
      font-size: 2.25rem;
      font-weight: bold;
      margin: 0;
      @include mixins.colors;
    }
    .description {
      margin: 1rem 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .list {
    @include mixins.fancy-scrollbar;
    padding: 0;
    margin: 1rem 0;
    overflow-y: auto;
    min-width: 175px;
    max-height: 150px;
    font-size: .75rem;
    @include mixins.md-up {
      flex: 1;
      padding: 0 0 0 1rem;
      margin: .5rem 0 .5rem 0;
      max-height: unset;
      border-left: 1px solid variables.$gray;
    }
    ol {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        &:not(:first-child) {
          margin-top: .5rem;
        }
        .marker span {
          background-color: variables.$pink;
          color: white;
          border-radius: 50%;
          padding: .25rem;
          display: flex;
          line-height: 1;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1 / 1;
          min-width: 23px;
        }
        .content {
          color: variables.$dark-gray;
          margin-left: .5rem;
        }
      }
    }
  }

}
