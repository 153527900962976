@use "../variables";

.ErrorModal {
  .body {
    ul {
      color: variables.$red;
      margin: 0;
    }
  }
}
