@use '../variables';

.Track {
  overflow: auto;
  @media only screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  ol {
    position: relative;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-inline: 5rem;
    padding: 0;
    &:before {
      content: '';
      display: block;
      height: 0.5rem;
      width: 100%;
      background-color: variables.$light-gray;
      position: absolute;
      top: 1.25rem;
      z-index: 0;
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
