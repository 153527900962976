@use '../variables';

.tooltipIcon {
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background-color: variables.$darker-gray;
  color: white;
  font-size: 0.5rem;
  font-weight: bold;
  font-family: monospace;
  margin-left: 0.25rem;
  visibility: visible;
  display: inline-flex;
  vertical-align: super;
  justify-content: center;
  align-items: center;
}
.tooltipHover {
  display: flex;
  position: absolute;
  width: 5rem;
  height: 5rem;
  &.hidden {
    visibility: hidden;
  }
}
.Tooltip {
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  position: fixed;
  background-color: variables.$dark-gray;
  padding: 0.5rem;
  display: block;
  max-width: 500px;
  width: max-content;
  z-index: 200;
  box-shadow: 1px 1px 5px variables.$darker-gray;
  &.hidden {
    visibility: hidden;
  }
  @media only screen and (max-width: 1000px) {
    max-width: 400px;
    @media only screen and (max-width: 800px) {
      max-width: 300px;
    }
    @media only screen and (max-width: 600px) {
      max-width: 200px;
    }
    @media only screen and (max-width: 400px) {
      max-width: 100px;
    }
  }
  & > .headline {
    font-size: 1.0625rem;
    display: flex;
    align-items: center;
    line-height: 1;
    svg {
      margin-right: .25rem;
      position: relative;
      top:- 1px;
    }
  }
  & > p:first-child {
    margin-top: 0;
  }
  & > p:last-child {
    margin-bottom: 0;
  }
}
