@use '../variables';

.TextareaInput {
  textarea {
    display: block;
    width: 100%;
    margin-top: 5px;
    border: 1px solid #dddfe1;
    font-size: .88rem;
    padding: 1rem;
    font-family: Roboto, sans-serif;
    line-height: 1.2;
    min-height: 8.5rem;
    resize: none;
    &::placeholder {
      color: #abafb3;
    }
  }
  &.has-error {
    label {
      color: variables.$red
    }
    textarea {
      background-color: lighten(variables.$red, 28%);
    }
  }
}
