@use '../variables';

.Title {
  background-color: variables.$light-turquoise;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: .5rem 1rem;
  padding: 1.75rem 2rem;
  height: 5rem;
  &.gray {
    background-color: darken(variables.$light-gray, 3%);
  }
}
