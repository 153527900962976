@use '../mixins';
@use '../variables';

.TeaserWidget {

  color: white;
  @include mixins.horizontal-color-gradients;

  @include mixins.sm-up {
    height: 331.5px;
  }

  .widget-wrapper {

    display: flex;
    flex-direction: row;
    margin: 1rem;

    .number-wrapper {

      font-size: 2.25rem;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin: 1rem 0 1rem 1rem;

      .number {
        margin-bottom: 0;
      }

      .dot {
        position: relative;
        background: #fff;
        opacity: 15%;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        bottom: 2.6rem;
        right: 1.25rem;
      }

    }

    .text-wrapper {

      margin: 1rem;

      .headline {
        font-size: 1.25rem;
      }

      .text {
        font-size: .88rem;
      }

      .call-to-action {
        font-size: .95rem;
        text-decoration: underline;
        text-underline-offset: 3px;
      }

    }

  }

}
