@use '../variables';

.TrackStation {
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 3rem;
  z-index: 1;
  &:last-child > .link >  div:after,
  &:first-child > .link > div:before{
    display: none;
  }
  &.active > .link {
    pointer-events: none;
    & > div {
      color: variables.$light-turquoise;
      background: linear-gradient(180deg, #9c58ff 0%, #d68afd 100%);
      border: none;

      &:before, &:after {
        top: .5rem;
      }

      &.active-first:before {
        display: none;
      }

      &.active-last:after {
        display: none;
      }
    }
  }
  &.placeholder-start, &.placeholder-end {
    display: none;
  }
  & > .link {
    text-decoration: none;
    color: variables.$darker-gray;

    & > div {
      position: relative;
      display: inline-block;
      height: 3rem;
      width: 3rem;
      border-color: variables.$purple;
      border-style: solid;
      border-width: 0.1rem;
      border-radius: 5rem;
      line-height: 3rem;
      color: variables.$purple;
      text-align: center;
      background: white;

      &:before, &:after {
        content: '';
        display: block;
        height: 2rem;
        width: 2rem;
        transform: rotate(45deg) skew(-30deg, -30deg);
        background-color: variables.$light-gray;
        position: absolute;
        top: calc(.5rem - 1px);
        z-index: -1;
      }

      &:before {
        right: 1.5rem;
      }

      &:after {
        left: 1.5rem;
      }

      &.last-index {
        display: none;
      }
    }

    & > p {
      inline-size: 8rem;
      overflow-wrap: break-word;
    }
  }

    @media only screen and (max-width: 1200px) {
      display: none;
      & > .link > div {
        &:before, &:after {
          display: none;
        }
      }
      &.active, &.pre-active, &.post-active {
        display: flex;
        & > .link {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 120%;
          }
        }
      }
      &.pre-active > .link:after {
        margin-right: 1rem;
      }
      &.post-active > .link:after {
        margin-left: 1rem;
      }
      &.pre-active > .link > div,
      &.post-active > .link > div,
      &.placeholder-start > .link > div,
      &.placeholder-end > .link > div {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        top: 0.25rem;
        justify-content: center;
        align-items: center;
        opacity: 70%;
      }
      &.pre-active, &.placeholder-start {
        left: 1rem;
        top: -0.25rem;
        align-items: flex-end;
      }
      &.post-active, &.placeholder-end {
        right: 1rem;
        top: -0.25rem;
        align-items: flex-start;
        z-index: 0;
      }
      &.pre-active > .link > p, &.active > .link > p, &.post-active > .link > p {
        position: relative;
        display: block;
      }
      &.pre-active > .link > p, &.post-active > .link > p {
        opacity: 70%;
        max-width: 5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active > .link > p {
        justify-content: center;
      }
      &.pre-active > .link > p {
        justify-content: end;
        right: 1rem;
        top: -0.5rem;
      }
      &.post-active > .link > p {
        justify-content: start;
        left: 1rem;
        top: -0.5rem;
      }
      & > .link > div {
        &.last-index {
          display: none;
        }
      }
      &.placeholder-start, &.placeholder-end {
        display: flex;
        visibility: hidden;
      }
    }
    @media only screen and (max-width: 576px) {
      & > .link > div {
        &.current-index {
          display: none;
        }
      }
      &.active > .link > div {
        &.last-index {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.pre-active, &.post-active {
        display: none;
      }
      &.active > .link > p {
        top: 0;
      }
      &.placeholder-start, &.placeholder-end {
        display: none;
      }
    }
}
