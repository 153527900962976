@use '../variables';

.DropzoneInput {
  .zone {
    margin-top: 5px;
    border: 2px dashed #cccccc;
    background-color: #7dcbce26;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 5rem;
    font-size: .88rem;
    color: #ABAFB3;
    cursor: pointer;
    text-align: center;
    span {
      color: variables.$turquoise;
    }
    & > .info-text {
      font-size: .75rem;
    }
  }
  ul {
    list-style-type: none;
    margin: 1rem 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    li {
      background-color: variables.$light-gray;
      font-size: .88rem;
      border-radius: 3px;
      padding: .25rem .25rem .25rem .5rem;
      display: flex;
      align-items: center;
      position: relative;
      span {
        display: flex;
        cursor: pointer;
        margin-left: 2rem;
        svg {
          color: variables.$red;
          height: 1rem;
        }
      }
    }
  }
  &.drag-over .zone {
    border: 2px dashed variables.$green;
    background-color: lighten(#7dcbce26, 20%);
  }
  &.has-error {
    label {
      color: variables.$red;
    }
    .zone {
      background-color: lighten(variables.$red, 28%);
    }
  }
}
