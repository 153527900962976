@use '../variables';
@use '../mixins';

.BarChart {
  display: flex;
  flex-direction: column;

  @include mixins.sm-up {
    height: 3 * 150px + 2 * 31.5px;
  }

  & > .title {
    display: flex;
    justify-content: center;
    font-size: 1em;
    font-weight: bold;
  }

  & > .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    cursor: pointer;

    & > .disabled-navigation {
      opacity: 50%;
    }

    & > div {
      height: 1.2em;
    }

    & > p {
      margin: 0 0.5em;
      height: 1em;
    }
  }

  & > .board {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 1em;

    & > .y-axis {
      display: flex;
      flex-direction: column-reverse;
      min-width: max-content;

      & > .y-axis-section {
        text-align: right;

        & > p {
          margin: 0;
        }
      }
    }

    & > .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;
      padding-left: 1rem;
      top: 1em;

      & > .loading {
        height: 12em;
        width: 100%;
        position: absolute;
        top: 0;
        opacity: 30%;
        transform: scale(1, 1.15);
        display: flex;
        justify-content: center;
        align-items: center;

        & > * {
          transform: scale(2);
        }
      }

      & > .no-data {
        position: absolute;
        top: 0;
        height: 12em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: variables.$dark-gray;

        & > .IconWithText {
          justify-content: center;
        }
      }

      & > .column {
        display: flex;
        align-items: center;
        flex-direction: column;

        & > .background-bar {
          width: 0.5em;
          height: 12em;
          background-color: variables.$light-gray;
          border-radius: 0.25em;
          position: relative;
          bottom: 0.5em;
          align-items: flex-end;

          & > .bar {
            width: 0.5em;
            max-height: 12em;
            background-color: lighten(variables.$purple, 15%);
            border-radius: 0.25em;
            position: absolute;
            bottom: 0;

            &.hover {
              background-color: variables.$purple;
            }

            & > .hover-info {
              width: max-content;
              height: 10em;
              display: none;
              background-color: variables.$purple;
              position: relative;
              border-radius: 0.2em;
              top: -11em;
              z-index: 2;
              flex-direction: column;
              padding: 1em;
              transform: translate(-50%, 0);

              &::after {
                content: '';
                position: absolute;
                background-color: variables.$purple;
                width: 1em;
                height: 1em;
                transform: rotate(45deg);
                bottom: -0.5em;
                left: 50%;
                margin-left: -0.25em;
              }

              &.shown {
                display: flex;
              }

              & > h4 {
                color: white;
                margin: 0 0 1em 0;
              }

              & > p {
                margin: 0;
                color: white;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {

    & > .board {
      flex-direction: column-reverse;
      align-items: center;

      & > .y-axis {
        writing-mode: vertical-lr;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        top: 1em;
        left: 1.5em;
      }

      & > .content {
        flex-direction: column;
        width: max-content;
        padding-left: 0;

        & > .loading {
          height: 100%;
          width: 10em;
        }

        & > .column {
          flex-direction: row-reverse;

          & > .background-bar {
            height: 0.8em;
            width: 12em;
            left: 1em;
            bottom: unset;

            & > .bar {
              height: 0.8em;
              max-width: 12em;

              & > .hover-info {
                transform: translate(30%, 0);
              }
            }
          }
        }

        & > .no-data {
          width: 12em;
          height: 100%;
          text-align: center;
        }
      }
    }
  }
}
