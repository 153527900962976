.ButtonWithTextInput {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
  .TextInput {
    width: 100%;
    flex: 1 0 15rem;
  }
  .buttons {
    display: flex;
    gap: .5rem;
    margin-bottom: .25rem;
    flex-shrink: 0;
    flex-wrap: wrap;
    max-width: 100%;
  }
}
