.CheckboxInput {
  label {
    display: flex;
    gap: .5rem;
    input {
      all: unset;
      flex-shrink: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      border: 1px solid #dddfe1;
      &:checked:before {
        content: '\2714';
        font-size: 1rem;
        line-height: 1;
        display: block;
        text-align: center;
        position: relative;
        top: 1px;
      }
    }
  }
}

.PublicModal .CheckboxInput {
  input {
    border: 1px solid white;
  }
}
