@use '../variables';

.StageTitle {

  display: flex;
  gap: .5rem;
  align-items: flex-start;
  font-size: 1.25rem;

  a {
    color: inherit;
    svg {
      height: 1rem;
    }
  }

  & + * {
    margin-top: 1rem;
  }

  &.disabled {
    color: variables.$dark-gray;
    & .feather {
      color: variables.$darker-gray;
    }
  }

  &.subtitle {
    font-size: .9rem;
    & .feather {
      width: .8rem;
    }
  }

}
