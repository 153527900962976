@use '../variables';
@use '../mixins';

footer {
  @include mixins.box-shadow;
  background-color: white;
  color: #898989;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font: normal normal normal 14px/1.2 Roboto;
  padding: 0 1rem;
  a {
    color: variables.$turquoise;
    text-decoration: none;
  }
  & > div {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    & > div {
      display: flex;
      gap: .5rem;
    }
  }
}
