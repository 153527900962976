@use '../variables';
@use '../mixins';

.Pagination {
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  @include mixins.md-up {
    flex-wrap: nowrap;
  }
  .pages {
    display: flex;
    order: 3;
    gap: 1rem;
    min-width: 100%;
    justify-content: center;
    margin-top: .5rem;
    flex-wrap: wrap;
    @include mixins.md-up {
      margin: 0;
      order: 2;
      min-width: auto;
      flex-wrap: wrap;
    }
    & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2rem;
    min-height: 2rem;
    border: none;
    background-color: transparent;
    padding: 0;
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: 0 1px 2px 1px rgba(130, 130, 130, 0.1);
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
      color: rgba(130, 130, 130, .5);
      font-size: smaller;
      text-decoration: none;
    }
    }
    & > .active {
      background-color: variables.$turquoise;
      box-shadow: none;
      span {
        color: white;
      }
    }
  }
  .arrow-left, .arrow-right {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    box-shadow: 0 1px 2px 1px rgba(130, 130, 130, .1);
    svg {
      width: 1rem;
      height: 1rem;
      color: rgba(130, 130, 130, .5);
    }
  }
  .arrow-left {
    margin-right: 1rem;
    order: 1;
  }
  .arrow-right {
    order: 2;
    @include mixins.md-up {
      order: 3;
      margin-left: 1rem;
    }
  }
  & > .inactive {
    cursor: not-allowed;
    svg {
      color: rgba(130, 130, 130, .1);
    }
  }
}
