@use '../variables';

.Breadcrumb {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2px 5px;
    font-size: .75rem;
    color: variables.$dark-gray;
    li:not(:last-child) {
      a {
        color: variables.$turquoise;
      }
      &:after {
        content: '|';
        margin-left: 5px;
        position: relative;
        top: -1px;
        color: variables.$dark-gray;
      }
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
