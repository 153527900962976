@use '../mixins';

.RowActionButton {
  border: none;
  background: none;
  padding: 0;
  display: inline-flex;
  cursor: pointer;
  @include mixins.colors;
  svg {
    width: 1rem;
  }
  &:not(:first-child) {
    margin-left: .5rem;
  }
}
