@use '../mixins';
@use '../variables';

.EventWidget {

  .date {
    margin: 0;
    font-size: 1.75rem;
    font-weight: bolder;
    @include mixins.colors;
  }

  .time {
    margin: 0;
    @include mixins.colors;
  }

  .text {
    margin: 7px 0 0 0;
    color: variables.$dark-gray;
    font-size: .75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}
