.IconWithText {
  display: flex;
  flex-wrap: wrap;
  gap: .25rem;
  &.align-center {
    text-align: center;
  }
  &.align-left {
    text-align: left;
  }
  svg {
    flex-shrink: 0;
    position: relative;
    top: -2px;
  }
}
