@use "../variables";
@use "../mixins";

.Image {
  display: inline-flex;
  flex-wrap: wrap;
  @include mixins.box-shadow;
  font-size: .9rem;
  .basics {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1.5rem;
    color: inherit;
    text-decoration: none;
    text-align: center;
    span {
      width: 150px;
      word-break: break-word;
    }
    img {
      width: 10rem;
      height: auto;
      margin-bottom: 1.5rem;
    }
  }
  .details {
    flex-grow: 1;
    padding: 1.5rem 1.5rem;
    background-color: variables.$light-gray;
    p {
      margin: 1rem 0 0 0;
      word-break: break-word;
      &:first-child {
        margin-top: 0;
      }
      a .feather {
        width: 1rem;
        height: 1rem;
        color: variables.$darker-gray
      }
      .date {
        white-space: nowrap;
      }
      .name {
        display: inline-flex;
        gap: .5rem;
        margin-left: .25rem;
        align-items: flex-end;
      }
    }
  }
}
