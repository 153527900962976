@use '../variables';

.DateDisplay {
  display: flex;
  flex-direction: column;
  .date-bar {
    display: flex;
    margin-top: 1rem;
    gap: .5rem;
    span {
      border: 1px solid variables.$gray;
      padding: .5rem;
    }
  }
}
