@use "sass:math";
@use '../variables';

.TextInput {

  label {
    display: inline-block;
    margin-bottom: 5px;
  }

  .input-wrapper {
    position: relative;
  }

  input {

    $icon-size: 1.25rem;
    display: block;
    width: 100%;
    font-size: .88rem;
    line-height: 1;
    padding: 1rem;
    border: 1px solid #dddfe1;
    font-family: Roboto, sans-serif;

    &::placeholder {
      color: #abafb3;
    }

    &.has-icon {
      padding-right: $icon-size * 2;
    }

    & + span {
      cursor: pointer;
      display: flex;
      position: absolute;
      top: calc(50% - #{$icon-size} / 2);
      right: math.div($icon-size, 2);

      svg {
        width: $icon-size;
        height: $icon-size;
      }

    }

    &:focus {
      outline: none;
    }

    &[readonly] {
      background-color: variables.$light-gray;
    }

  }

  &.has-error {
    label {
      color: variables.$red
    }
    input {
      background-color: lighten(variables.$red, 28%);
    }
  }

}
