@use '../variables';

.RadioButton {
  margin-right: 1rem;
  display: flex;
  cursor: pointer;
  input[type="radio"] {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5rem 0 0;
    min-width: 1rem;
    max-width: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    border: 1px solid variables.$gray;
    border-radius: 50%;
    box-shadow: 0 0 3px variables.$gray;
    cursor: pointer;
  }
  input[type="radio"]:checked::before {
    content: "";
    border: .3rem solid variables.$green;
    border-radius: 50%;
  }
}
