@use '../../../variables';
@use '../../../mixins';

.videoSetup {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 4rem;
  
  & > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      span {
        margin-top: .5rem;
      }

      &:hover {
        span {
          color: variables.$turquoise;
        }
      }
    }
  }
}
