@use '../mixins';

.Progress {
  width: 3rem;
  height: 3rem;
  @include mixins.vertical-color-gradients;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  font-size: 1rem;
  line-height: 1;
}
