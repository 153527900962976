@use '../variables';
@use '../mixins';

.Table {
  .table-wrapper {
    overflow-x: auto;
    width: calc(100% + 5px);
    table {
      width: calc(100% - 10px);
      margin: 0 auto;
      border-collapse: separate;
      border-spacing: 0 1rem;
      .draggable {
        cursor: grab;
        & td:first-child {
          width: 1rem;
          padding-right: 0;
        }
      }
      tbody tr {
        @include mixins.box-shadow;
        &.drop-placeholder {
          ruby-align: center;
          outline: 1px solid variables.$turquoise;
        }
        &.drag-item {
          display: none;
        }
        &.order-changed {
          .RowActionButton, button, a {
            opacity: .6;
            pointer-events: none;
            filter: grayscale(.4);
          }
        }
        .dragging-icon {
          padding-right: 0;
          width: 24px;
        }
      }
      th {
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 400;
        color: #464a53;
        padding: 0 1rem;
        &.sortable span {
          display: flex;
          cursor: pointer;
        }
        &.active {
          color: variables.$purple;
        }
        div {
          display: flex;
          align-items: center;
        }
        svg {
          width: 1rem;
          margin-left: .25rem;
        }
        &.type-number {
          div {
            justify-content: right;
          }
        }
        &.type-image {
          div {
            justify-content: center;
          }
        }
      }
      td {
        color: #6a707e;
        white-space: nowrap;
        padding: 1rem;
        &.type-number {
          text-align: right;
        }
        &.type-actions {
          text-align: right;
        }
        &:not(.type-actions) a {
          color: inherit;
          text-decoration: none;
        }
        img {
          height: 3rem;
          object-fit: cover;
          display: block;
          margin: -.375rem auto;
          &.round {
            width: 3rem;
            border-radius: 1.5rem;
          }
        }
      }
    }
  }
  .table-pagination {
    margin-top: 1rem;
    padding: .5rem;
    &.drag {
      .arrow-left:not(.inactive), .arrow-right:not(.inactive), .pages > button {
        border: 1px solid variables.$turquoise;
      }
    }
  }
}
