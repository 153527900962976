@use '../mixins';

.ModalWrapper {
  position: fixed;
  z-index: 1;
  top: 50px;
  max-height: calc(100vh - 100px);
  @include mixins.md-up {
    top: 100px;
    max-height: calc(100vh - 200px);
  }
  width: calc(100% - 1.5rem);
  max-width: 750px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.Modal {
  background-color: white;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 3px 10px #77777780;
  color: #464A53;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .2);
    z-index: -1;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid #dddfe1;
    padding-bottom: .25rem;
    .title {
      position: relative;
      top: 2px;
    }
    .closer {
      cursor: pointer;
      display: flex;
    }
  }
}
