.ButtonBar {
  margin-top: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
}
