@use '../variables';

.FormErrors {
  margin-top: 1.75rem;
  padding: 1rem;
  border: 1px solid darken(variables.$red, 30%);
  background-color: lighten(variables.$red, 28%);
  color: variables.$red;
  p {
    margin: 0;
  }
  ul {
    margin: 1rem 0 0 0;
    padding-left: 1rem;
  }
}
