@use '../variables';
@use '../mixins';

.Accordion {
  border: 1px solid variables.$gray;
  .title {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    @include mixins.sm-up {
      flex-wrap: nowrap;
    }
    padding: .5rem 1rem;
    background-color: variables.$light-gray;
    color: variables.$dark-gray;
    cursor: pointer;
    line-height: 1.25rem;
    span {
      flex-grow: 1;
      flex-basis: 100%;
      position: relative;
      top: 1px;
      hyphens: auto;
      width: 100%;
      @include mixins.sm-up {
        order: 0;
        margin-top: 0;
      }
    }
    & > svg {
      width: 22px;
      height: 22px;
      transform: scale(1.09);
      flex-shrink: 0;
      margin-left: 1rem;
    }
    button {
      border: none;
      background: none;
      padding: 0;
      display: inline-flex;
      cursor: pointer;
      margin-left: 1rem;
      width: 1.2222rem;
      height: 1.2222rem;
      transform: scale(1.09);
      @include mixins.colors;
      & + button, & + a {
        margin-left: .5rem;
      }
      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
    a {
      color: inherit;
      display: inline-flex;
      width: 1.2222rem;
      height: 1.2222rem;
      transform: scale(1.09);
      @include mixins.colors;
      & + button, & + a {
        margin-left: .5rem;
      }
      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
    &:empty:before {
      content: '\200b';
    }
  }
  .body-wrapper {
    overflow: hidden;
    transition: max-height 200ms ease-out;
    & > .body {
      border-top: 1px solid #dddfe1;
      padding: 1rem;
      &:empty {
        border-top: none;
        padding: 0;
      }
      & > *:first-child {
        margin-top: 0;
      }
      & > *:last-child {
        margin-bottom: 0;
      }
      & > p {
        line-height: 1.41;
      }
    }
  }
  & ~ .Accordion {
    margin-top: 1rem;
  }
}
