@use './TextInput';

.ColorInput {

  @extend .TextInput;

  .input-wrapper {

    display: flex;

    input[type="text"] {
      flex-grow: 1;
    }

    input[type="color"] {
      all: unset;
      -webkit-appearance: none;
      align-self: stretch;
      cursor: pointer;
      width: calc(1rem + 7px);
      padding: 1rem;
      border: 1px solid #dddfe1;
      border-left-width: 0;
      flex-grow: 1;
      flex-basis: available;
      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      &::-webkit-color-swatch {
        border: none;
      }
    }
  }

}
