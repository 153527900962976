@use '../variables';

.OfferFileOptionSelect {
  border: 1px solid variables.$gray;
  padding: 1rem;
  .file-label {
    background-color: #f6f6f6;
    font-size: 0.88rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: max-content;
    padding: .2rem;
    margin-bottom: .5rem;
    &.no-link {
      cursor: default;
    }
    svg {
      display: flex;
      height: 1rem;
    }
    a {
      text-decoration: none;
      color: variables.$darker-gray;
    }
    .x-icon {
      display: flex;
      color: variables.$red;
      cursor: pointer;
      margin-left: .5rem;
    }
  }
  .radio-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
}
