@use '../variables';

.ImageInput {
  label {
    display: block;
    margin-bottom: 5px;
  }
  .frame {
    border: 1px solid #dddfe1;
    position: relative;
    padding: 1rem;
    button {
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
