@use 'variables';

@mixin sm-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin background-colors {
  &.turquoise {
    background-color: variables.$turquoise;
  }
  &.yellow {
    background-color: variables.$yellow;
  }
  &.light-yellow {
    background-color: variables.$light-yellow;
  }
  &.red {
     background-color: variables.$red;
  }
  &.green {
    background-color: variables.$green;
  }
  &.orange {
    background-color: variables.$orange;
  }
  &.purple {
    background-color: variables.$purple;
  }
}

@mixin colors {
  &.turquoise {
    color: variables.$turquoise;
  }
  &.yellow {
    color: variables.$yellow;
  }
  &.red {
    color: variables.$red;
  }
  &.green {
    color: variables.$green;
  }
  &.blue {
    color: variables.$blue;
  }
  &.purple {
    color: variables.$purple;
  }
  &.pink {
    color: variables.$pink;
  }
}

@mixin advanced-colors($property, $selector: '&') {
  &.turquoise {
    #{$selector} {
      #{$property}: variables.$turquoise;
    }
  }
  &.yellow {
    #{$selector} {
      #{$property}: variables.$yellow;
    }
  }
  &.red {
    #{$selector} {
      #{$property}: variables.$red;
    }
  }
  &.green {
    #{$selector} {
      #{$property}: variables.$green;
    }
  }
  &.blue {
    #{$selector} {
      #{$property}: variables.$blue;
    }
  }
  &.purple {
    #{$selector} {
      #{$property}: variables.$purple;
    }
  }
  &.pink {
    #{$selector} {
      #{$property}: variables.$pink;
    }
  }
}

@mixin vertical-color-gradients {
  &.purple {
    background-image: linear-gradient(
      #{map-get(variables.$gradient-purple, "start")} 0%, #{map-get(variables.$gradient-purple, "stop")} 100%
    );
  }
  &.orange {
    background-image: linear-gradient(
      #{map-get(variables.$gradient-orange, "start")} 0%, #{map-get(variables.$gradient-orange, "stop")} 100%
    );
  }
  &.turquoise {
    background-image: linear-gradient(
      #{map-get(variables.$gradient-turquoise, "start")} 0%, #{map-get(variables.$gradient-turquoise, "stop")} 100%
    );
  }
}

@mixin horizontal-color-gradients {
  &.purple {
    background: linear-gradient(
      90deg,
      #{map-get(variables.$gradient-purple, "start")} 0%,
      #{map-get(variables.$gradient-purple, "stop")} 100%
    );
  }
  &.orange {
    background: linear-gradient(
      90deg,
      #{map-get(variables.$gradient-orange, "start")} 0%,
      #{map-get(variables.$gradient-orange, "stop")} 100%
    );
  }
  &.turquoise {
    background: linear-gradient(
      90deg,
      #{map-get(variables.$gradient-turquoise, "start")} 0%,
      #{map-get(variables.$gradient-turquoise, "stop")} 100%
    );
  }
}

@mixin box-shadow {
  box-shadow: 0.47px 3px 10px #7777771A;
}

@mixin fancy-scrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    background: variables.$light-gray;
    border-radius: 3rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3rem;
    height: 2rem;
  }
  &.yellow {
    scrollbar-color: variables.$yellow variables.$light-gray;
    &::-webkit-scrollbar-thumb {
      background: variables.$yellow;
    }
  }
  &.red {
    scrollbar-color: variables.$red variables.$light-gray;
    &::-webkit-scrollbar-thumb  {
      background: variables.$red;
    }
  }
  &.turquoise {
    scrollbar-color: variables.$turquoise variables.$light-gray;
    &::-webkit-scrollbar-thumb {
      background: variables.$turquoise;
    }
  }
  &.green {
    scrollbar-color: variables.$green variables.$light-gray;
    &::-webkit-scrollbar-thumb {
      background: variables.$green
    }
  }
  &.blue {
    scrollbar-color: variables.$blue variables.$light-gray;
    &::-webkit-scrollbar-thumb {
      background: variables.$blue;
    }
  }
  &.purple {
    scrollbar-color: variables.$purple variables.$light-gray;
    &::-webkit-scrollbar-thumb {
      background: variables.$purple;
    }
  }
  &.pink {
    scrollbar-color: variables.$pink variables.$light-gray;
    &::-webkit-scrollbar-thumb {
      background: variables.$pink;
    }
  }
}
