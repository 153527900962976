@use '../../../variables';
@use '../../../mixins';

.RecordVideo {
  .modal {
    gap: unset;
  }
  .recordStartOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    div:first-child {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .8;
      background-color: white;
      z-index: 1;
    }
    div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      height: 100%;
      & * {
        z-index: 2;
        color: #464A53;
      }
      .counter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 10rem;
        border: 6px solid rgba(0,0,0,.57);
        border-radius: 7.5rem;
        font-size: 6rem;

        @include mixins.sm-up {
          width: 12rem;
          height: 12rem;
          font-size: 8rem;
        }

        @include mixins.lg-up {
          width: 14rem;
          height: 14rem;
          font-size: 11rem;
        }
      }
    }
  }
  .videoContainer {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .audioOnly {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.recording .audioOnly svg circle {
      display: inline;
    }
  }
  .controlsContainer {
    position: relative;
    height: 6rem;
    background-color: variables.$turquoise;

    .startRecording, .stopRecording {
      position: absolute;
      top: -2rem;
      left: calc(50% - 2rem);
      width: 4rem;
      height: 4rem;
      border: none;
      border-radius: 2rem;
      cursor: pointer;
    }

    .startRecording {
      background-color: #F863B7;
      color: white;
    }

    .stopRecording {
      background-color: white;
      &::before {
        content: '';
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 auto;
        border-radius: 5px;
        background-color: #F863B7;
      }
    }

    p {
      position: absolute;
      left: 50%;
      bottom: 1rem;
      transform: translate(-50%, 0);
      color: white;
    }
  }
}
