@use '../mixins';
@use '../variables';

.LinkWidget {

  .icon, &:active.icon {
    @include mixins.colors;
  }

  svg {
    width: 36px;
    height: 36px;
  }

  .headline {
    font-size: .75rem;
    text-transform: uppercase;
    margin: .25rem 0 0 0;
    color: variables.$dark-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text {
    margin: .25rem 0 0 0;
    color: variables.$dark-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include mixins.md-up {
    flex-direction: row;
    align-items: center;
    svg {
      width: 75px;
      height: 75px;
    }
    .texts {
      flex-grow: 1;
      margin-left: .75rem;
    }
    .headline {
      font-size: .75rem;
    }
    .text {
      font-size: 1.25rem;
    }
  }

}
