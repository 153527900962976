@use '../mixins';
@use '../variables';

.FactWidget {

  .number {
    font-size: 2.25rem;
    font-weight: bold;
    margin: 0;
    @include mixins.colors;
  }

  .description {
    margin: 1rem 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .details {
    font-size: .75rem;
    color: variables.$dark-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: .25rem 0 0 0;
  }

  &.has-details .description {
    margin: 0 0 0 0;
  }

}
