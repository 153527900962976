@use '../variables';
@use '../mixins';

.DonutChart {
  position: relative;
  max-width: 700px;
  padding: 1rem;
  @include mixins.md-up {
    padding: 1.75rem;
  }
  @include mixins.box-shadow;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .label {
    pointer-events: none;
    &:empty {
      display: none;
    }
    background-color: rgba(0, 0, 0, .3);
    color: white;
    border-radius: .25rem;
    line-height: 1.2;
    padding: .25rem .5rem;
    font-size: .75rem;
    display: inline;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    min-height: 40px;
    .period {
      display: flex;
      overflow: hidden;
      span {
        display: flex;
        align-items: center;
        height: 40px;
        border: 2px solid variables.$gray;
        padding: 0 .5rem;
        &:not(&:first-child) {
          margin-left: -2px;
        }
      }
    }
  }

  .diagram {
    position: relative;
    margin-top: 2rem;
    svg {
      flex-grow: 1;
      max-width: 300px;
      path {
        @include mixins.advanced-colors(stroke);
        &:hover {
          opacity: .75;
        }
      }
    }
    & .pointer {
      cursor: pointer;
    }
    ul {
      margin: 0;
      padding: 0;
      width: max-content;
      max-width: 100%;
      li {
        @include mixins.colors;
        list-style-type: none;
        display: flex;
        &:not(&:first-child) {
          margin-top: .25rem;
        }
        &:before {
          content: '';
          flex-shrink: 0;
          display: inline-block;
          height: .75rem;
          width: .75rem;
          border-radius: 50%;
          margin-top: .2rem;
          margin-right: .25rem;
        }
        @include mixins.advanced-colors(background-color, '&:before');
      }
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}
