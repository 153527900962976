@use "../mixins";

.Row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
  flex-direction: column;
  align-items: flex-start;
  & + .Row {
    margin-top: 1.75rem;
  }
  @include mixins.lg-up {
    flex-direction: row;
    width: var(--lg-width);
  }
  & > * {
    width: 100%;
    flex: var(--lg-max-items, 1 0 0);
  }
}
