$turquoise: #7dcbce;
$light-turquoise: #e9f0f0;
$yellow: #fdae3b;
$light-yellow: #eacc5a ;
$orange: #f48665;
$red: #f8636d;
$green: #71d875;
$blue: #16aeed;
$purple: #7f61f1;
$pink: #f863b7;

$light-gray: #f6f6f6;
$gray: #dddddd;
$dark-gray: #828282;
$darker-gray: #464a53;

$gradient-purple: ("start": #9a56ff, "stop": #d78afd);
$gradient-orange: ("start": #f48665, "stop": #fda23f);
$gradient-turquoise: ("start": #7dcbc8, "stop": #7dcdb1);
