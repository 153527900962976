@use 'mixins';
@use 'variables';

.Screen {

  display: flex;
  flex-direction: column;

  @include mixins.md-up {
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    grid-template-rows: 1fr auto;
    .MainMenu { grid-area: 1 / 1 / 3 / 2; }
    .main { grid-area: 1 / 2 / 2 / 3; }
    .Footer { grid-area: 2 / 2 / 3 / 3; }
  }

  main {
    flex-grow: 1;
  }

}
