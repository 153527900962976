@use '../variables';

.ico {
  margin: .50em;
  padding-top: .30em;
  cursor: pointer;
  max-width: .5em;
}

.ico:hover {
  color: variables.$turquoise;
}

.Icons {
  margin-top: 1rem;
  .disabled {
    opacity: .2;
  }
}

