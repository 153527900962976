@use '../variables';
@use '../mixins';

.Video {

  position: relative;

  &.info-container {
    padding-bottom: 3.25rem;
  }

  .videoContainer {
    position: relative;
    padding-bottom: 56.25%;
    background-color: variables.$gray;

    & > div:first-child {
      position: unset!important;
    }

    .memo-image {
      display: flex;
      justify-content: center;

      svg {
        position: absolute;
        top: 19%;
        width: 70%;
        height: 70%;
        .first-color {
          fill: variables.$turquoise;
        }
        .second-color {
          fill: white;
        }
        .tape-color {
          stroke: black;
        }
        .outline-color {
          stroke: white;
        }
        .accent-color {
          stroke: variables.$dark-gray;
        }
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .videoOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      div:first-child {
        position: absolute;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        background-color: #fff;
        opacity: .2;
      }

      div:last-child {
        position: absolute;
        border-style: solid;
        border-width: 1rem 0 1rem 1.75rem;
        border-color: transparent transparent transparent #fff;
        margin-left: 10px;
      }
    }

    & .videoIsProcessingOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .controlsContainer {
    height: 6rem;
    background-color: variables.$turquoise;
    color: #fff;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 15% 1fr 15%;

    .controls {
      grid-area: 2 / 1 / 3 / 1;

      .play, .pause {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
      }

      .play {
        border-style: solid;
        border-width: .75rem 0 .75rem 1.25rem;
        border-color: transparent transparent transparent #fff;
      }

      .pause {
        height: 1.25rem;
        border-style: double;
        border-width: 0 0 0 1.25rem;
        border-color: #fff;
      }
    }
  }

  .progressbarContainer {
    grid-area: 1 / 1 / 2 / 3;
    width: 85%;
    display: table;
    padding: .75rem 0;
    cursor: pointer;
    position: relative;

    .progressbar {
      -webkit-appearance: none;
      width: 100%;
      height: .33rem;
      background: #fff;
      outline: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        border: .33rem solid #fff;
        border-radius: .5rem;
        background-color: variables.$turquoise;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: .5rem;
        height: .5rem;
        border: .33rem solid #fff;
        border-radius: .5rem;
        background-color: variables.$turquoise;
        cursor: pointer;
      }
    }
  }

  .timeContainer {
    grid-area: 1 / 3 / 2 / 4;
    justify-self: center;
    font-size: .75rem;
  }

  .volumeContainer {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .volumeBar {
      -webkit-appearance: none;
      width: 0;
      height: .33rem;
      background: #fff;
      outline: none;
      transition: width ease 500ms;
      display: none;
      max-width: 8rem;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1rem;
        height: 1rem;
        border: .33rem solid #fff;
        border-radius: .5rem;
        background-color: variables.$turquoise;
        cursor: pointer;
        display: none;
      }

      &::-moz-range-thumb {
        width: .5rem;
        height: .5rem;
        border: .33rem solid #fff;
        border-radius: .5rem;
        background-color: variables.$turquoise;
        cursor: pointer;
        display: none;
      }
    }

    &:hover {
      .volumeIndicator {
        display: block;
      }

      .volumeBar {
        width: 100%;
        transition: width ease 500ms;
        display: block;

        &::-webkit-slider-thumb {
          display: block;
        }

        &::-moz-range-thumb {
          display: block;
        }
      }
    }

    i.fa-volume-down, i.fa-volume-up, i.fa-volume-off {
      position: relative;
      left: -.75rem;
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      width: 0;
      padding-right: 2rem;

      &::before {
        padding-inline: 1rem;
      }
    }
  }

  .playbackRateWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;


    .playbackRateContainer {
      position: relative;
      grid-area: 2 / 3 / 3 / 4;
      width: max-content;
      right: .5rem;

      .playbackRateIcon {
        height: 1.5rem;
        border: 2px solid white;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .5rem;
        font-size: .75rem;
        cursor: pointer;
      }

      .playbackRate {
        position: absolute;
        top: -.5rem;
        right: 0;
        transform: translateY(-100%);
        background-color: white;
        opacity: .9;
        color: variables.$darker-gray;

        span {
          display: block;
          padding: 1rem 1rem 0 1rem;
        }

        hr {
          margin: 1rem 0;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0 0 1rem 0;

          li {
            line-height: 1.4;
            padding: 0 1rem;
            cursor: pointer;

            &:hover {
              background-color: variables.$turquoise;
              color: white;
            }
          }
        }

        .active {
          background-color: variables.$turquoise;
          color: white;
        }
      }
    }
  }

  i {
    color: #fff;
    font-size: 1.25rem;
  }

  & > .info-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 2rem;
    padding: 1.5rem .5rem;
    background-color: variables.$light-gray;

    & > .video-name {
      display: block;
      white-space: nowrap;
      width: 70%;

      & > p {
        text-overflow: ellipsis;
        overflow: hidden;

      }
    }

    & > .video-actions {
      display: flex;
      width: 30%;
      top: 0.25rem;
      right: 0;
      justify-content: right;

      & > .download-button a {
        color: variables.$yellow;
      }

      & > .edit-button {
        color: variables.$blue;
        margin-left: .25rem;
      }

      & > .delete-button {
        color: variables.$red;
        margin-left: .25rem;
        cursor: pointer;
      }
    }
  }

}

.container-size-xxs .Video {
  .controlsContainer {
    grid-template-columns: 15% 1fr 30%;
  }
}

.container-size-xs .Video {
  .controlsContainer {
    grid-template-columns: 15% 1fr 25%;

    .timeContainer {
      font-size: 1rem;
      white-space: nowrap;
    }
  }
}

.container-size-sm, .container-size-md, .container-size-lg .Video {
  .videoOverlay {
    div:first-child {
      width: 8rem;
      height: 8rem;
      border-radius: 4rem;
    }

    div:last-child {
      border-width: 2rem 0 2rem 2.75rem;
    }
  }

  .controlsContainer {
    grid-template-columns: 15% 1fr 20%;

    .controls {
      grid-area: 1 / 1 / 3 / 2;

      .play {
        border-width: 1.25rem 0 1.25rem 1.75rem;
      }

      .pause {
        height: 2.25rem;
        border-width: 0 0 0 1.75rem;
      }
    }
    .progressbarContainer {
      grid-area: 1 / 2 / 2 / 3;
      width: 100%;
    }
    .timeContainer {
      grid-area: 1 / 3 / 2 / 4;
      font-size: 1rem;
    }
    .settingsGrid {
      grid-area: 2 / 2 / 3 / 4;
      display: flex;
      justify-content: flex-end;

      .volumeContainer {
        margin-bottom: .75rem;
      }
    }
  }

  i {
    font-size: 1.5rem;
  }
}
