@use '../variables';

.TitleHeading {
  font-size: 1.25rem;
  font-weight: normal;
  color: variables.$turquoise;
  margin: 0;
  &.gray {
    color: darken(variables.$dark-gray, 20%);
  }
}
