@use '../mixins';

.Badge {

  color: white;
  font-size: .75rem;
  padding: .25rem .75rem;
  border-radius: .25rem;
  @include mixins.background-colors;

  &.clickable {
    cursor: pointer;
  }

  &.highlighted:before {
    content: '✓ ';
  }

}
