@use '../mixins';
@use '../variables';

.Stage {

  margin: 1.75rem 0;
  padding: 1.75rem;

  &:not(.transparent) {
    background-color: white;
    @include mixins.box-shadow;
    @include mixins.md-up {
      padding: 1.75rem;
    }
  }

  hr {
    margin: 2rem 0;
    height: 1px;
    background-color: variables.$gray;
    border: 0;
  }

  a.default {
    color: inherit;
  }

  @include mixins.md-up {
    margin: 2.5rem;
    padding: 0;
  }

}
