@use '../variables';

$widthSwitchContainer: 2.125rem;
$widthSwitchButton: 1rem;
$switchButtonBorderSpacing: 0.125rem;

.Switch {
  display: flex;
  align-items: center;
  height: 1.25rem;
  width: $widthSwitchContainer;
  min-width: $widthSwitchContainer;
  border-radius: 5rem;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 0 0.4rem variables.$gray;
  .dot {
    position: relative;
    left: $switchButtonBorderSpacing;
    display: block;
    background-color: variables.$pink;
    height: 1rem;
    width: $widthSwitchButton;
    border-radius: 5rem;
    transition: all 200ms ease-out;
  }
  .dot-active {
    background-color: variables.$green;
    left: $widthSwitchContainer - $widthSwitchButton - $switchButtonBorderSpacing;
  }
}
