.video-box {
  position: relative;
  button {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 999;
    background-color: white;
    padding: .5rem;
    border-radius: 10%;
  }
}

.type-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
