@use '../../variables';
@use '../../mixins';

.VideoRecording {
  .videoRecordingContainer {
    position: relative;
    width: calc(100vw - 3rem);
    @include mixins.sm-up {
      width: 400px;
      min-height: calc(400px * 0.5625 + 8rem);
      max-width: unset;
    }
    @include mixins.md-up {
      width: 450px;
      min-height: calc(450px * 0.5625 + 8rem);
    }
    @include mixins.lg-up {
      width: 900px;
      min-height: calc(900px * 0.5625 + 8rem);
    }
    min-height: calc(100vw * 0.5625 + 8rem);
  }
}
