@use '../variables';

.Button {
  border: none;
  @mixin color($background-color, $color: white, $background: null) {
    background-color: $background-color;
    @if $background {
      background: $background;
    }
    color: $color;
    span.icon:before {
      background-color: lighten($background-color, 10%);
    }
  }
  &.turquoise {
    @include color(variables.$turquoise);
  }
  &.yellow {
    @include color(variables.$yellow);
  }
  &.red {
    @include color(variables.$red);
  }
  &.white {
    @include color(white, variables.$dark-gray);
  }
  &.gray {
    @include color(variables.$light-gray, variables.$dark-gray);
  }
  &.purple-gradient {
    @include color(#9c58ff, white, linear-gradient(0deg, #9c58ff 0%, #d68afd 100%));
  }
  &.orange-gradient {
    @include color(#f48665, white, linear-gradient(0deg, #f48665 0%, #fda23f 100%));
  }
  cursor: pointer;
  font: 400 normal normal 1rem/1.2 Roboto;
  padding: .75rem;
  border-radius: .25rem;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;
  &:hover {
    filter: brightness(1.05);
  }
  &:active {
    filter: brightness(1.1);
  }
  &:disabled {
    filter: grayscale(30%);
    opacity: .5;
    pointer-events: none;
  }
  span.text {
    position: relative;
    top: 1px;
  }
  span.icon {
    display: flex;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: calc(100% + 2 * .75rem);
      width: 1px;
      position: absolute;
      top: -.75rem;
    }
  }
  span.icon:first-child {
    padding-right: 1.5rem;
    &:before {
      right: .75rem;
    }
  }
  span.icon:last-child {
    padding-left: 1.5rem;
    &:before {
      left: .75rem;
    }
  }
  svg {
    height: 21px;
  }
}
