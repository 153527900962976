.logo-gallery-item {
  img {
    max-height: 5rem;
    width: auto;
    object-fit: scale-down;
  }
}

.logo-gallery-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  max-height: 1rem;
  img {
    display: flex;
    object-fit: scale-down !important;
    height: 1rem;
    width: 100%;
  }
}
