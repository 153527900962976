@use '../variables';
@use '../mixins';

.PublicModal {

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(to bottom right, #cac2a6, #81b2b8);
  }
  &.background-white:before {
    background-image: none;
    background-color: white;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .modal {

    display: flex;
    flex-direction: column;
    @include mixins.md-up {
      flex-direction: row-reverse;
    }
    box-shadow: 0 3px 10px #77777780;

    .content {
      background-color: variables.$turquoise;
      &.background-gray {
        background-color: #6e6e6e;
      }
      color: white;
      padding: 2rem;
      width: calc(100vw);
      max-width: 400px;
      @include mixins.sm-up {
        width: 400px;
        max-width: unset;
      }
      @include mixins.md-up {
        width: 450px;
      }
      @include mixins.lg-up {
        width: 500px;
      }
      h1 {
        margin: 0 0 1.75rem 0;
        font-size: 2rem;
      }
      a:not(.Button) {
        color: inherit;
        white-space: nowrap;
        flex: 0 1;
      }
      & > p {
        margin: 1.5rem 0;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .FormErrors {
          margin-top: 0;
        }
        .ButtonBar {
          margin-top: 0;
        }
      }
      & > .info-text {
        font-size: 0.8rem;
      }
    }

    .logo {

      padding: 2rem;
      background-color: white;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .25rem;
      text-transform: uppercase;
      line-height: 1;
      color: variables.$dark-gray;

      .brand {
        font-size: 1.75rem;
        font-weight: bold;
        margin-top: .5rem;
      }

      .claim {
        letter-spacing: -1px;
      }

    }

  }

  .Row {
    gap: 1.5rem;
    & + .Row {
      margin-top: 1.75rem;
    }
  }

  .TextInput {

    input {
      background-color: transparent;
      border: 1px solid white;
      color: white;
      &::placeholder {
        color: white;
        opacity: .5;
      }
    }

    &.has-error input {
      border: 1px solid variables.$red;
    }

  }

}
