@use '../mixins';

.Widget {

  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 7rem;
  text-decoration: none;
  background-color: white;
  @include mixins.box-shadow;

  @include mixins.md-up {
    flex-basis: 8.33rem;
    height: 150px;
    padding-left: 1.75em;
    padding-right: 1.75em;
  }

}
