@use '../variables';
@use '../mixins';

.StandaloneModal {

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image:
      radial-gradient(circle at 25% bottom, variables.$purple, transparent 50%),
      radial-gradient(circle at 75% top, variables.$turquoise, transparent 50%);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .modal {

    position: relative;
    background-color: white;
    text-align: center;
    width: max-content;
    max-width: 100%;
    padding: 1.5rem;
    @include mixins.md-up {
      padding: 3rem;
    }
    box-shadow: 0 3px 10px #77777780;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    .closer {
      position: absolute;
      display: flex;
      top: .25rem;
      right: .25rem;
      padding: .125rem;
      @include mixins.md-up {
        top: .5rem;
        right: .5rem;
        padding: .25rem;
      }
      color: variables.$dark-gray;
      cursor: pointer;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        stroke-width: 3.5;
      }
    }

    h1 {
      margin: 0;
      font-size: 2rem;
    }

    p {
      margin: 0;
    }

    & > svg {
      margin: 0 auto;
      max-width: 100%;
    }

    & > .ButtonBar {
      margin-top: 0;
    }

  }

}
