@use "../variables";

.DesignTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    margin-bottom: 2rem;
    box-shadow: 10px 17px 20px #00000042;
    cursor: pointer;
  }
  &.active-design {
    img {
      border: 3px solid variables.$turquoise;
      cursor: default;
    }
    & > div {
      & > button {
      display: none;
      }
    }
  }
  & >  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25%;
    button {
      margin-bottom: 1rem;
    }
  }
}
